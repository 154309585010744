// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._-_-_-profile-frontend-src-components-gamification-list-item-coins--item-coins{color:#FF5C5C;margin-top:16px;display:inline-flex;vertical-align:middle}._-_-_-profile-frontend-src-components-gamification-list-item-coins--item-coins__count{margin-left:8px}\n", ""]);
// Exports
exports.locals = {
	"item-coins": "_-_-_-profile-frontend-src-components-gamification-list-item-coins--item-coins",
	"itemCoins": "_-_-_-profile-frontend-src-components-gamification-list-item-coins--item-coins",
	"item-coins__count": "_-_-_-profile-frontend-src-components-gamification-list-item-coins--item-coins__count",
	"itemCoins__count": "_-_-_-profile-frontend-src-components-gamification-list-item-coins--item-coins__count"
};
module.exports = exports;
