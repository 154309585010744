// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._-_-_-profile-frontend-src-components--skills-button{border-radius:4px}._-_-_-profile-frontend-src-components--skills-button__more{background-color:#2B2C34;border-radius:4px;border:1px solid #3C3D47}._-_-_-profile-frontend-src-components--skills-button__more:hover:not(:focus):not([disabled]){background-color:#2B2C34;border-color:#3C3D47}._-_-_-profile-frontend-src-components--profile-avatar__upload{color:var(--myteam-themeVariables-colorTextOnBrand)}._-_-_-profile-frontend-src-components--slots-table{position:relative}._-_-_-profile-frontend-src-components--slots-table .ant-table-tbody>tr.ant-table-row:hover>td{background:var(--myteam-themeVariables-colorBrand08)}._-_-_-profile-frontend-src-components--slots-table .ant-table-tbody>tr.ant-table-row>td button{border-radius:4px}._-_-_-profile-frontend-src-components--event-detail-creating{height:100%}._-_-_-profile-frontend-src-components--event-detail-creating__section-scrollbars__view{z-index:0;max-width:none}\n", ""]);
// Exports
exports.locals = {
	"skills-button": "_-_-_-profile-frontend-src-components--skills-button",
	"skillsButton": "_-_-_-profile-frontend-src-components--skills-button",
	"skills-button__more": "_-_-_-profile-frontend-src-components--skills-button__more",
	"skillsButton__more": "_-_-_-profile-frontend-src-components--skills-button__more",
	"profile-avatar__upload": "_-_-_-profile-frontend-src-components--profile-avatar__upload",
	"profileAvatar__upload": "_-_-_-profile-frontend-src-components--profile-avatar__upload",
	"slots-table": "_-_-_-profile-frontend-src-components--slots-table",
	"slotsTable": "_-_-_-profile-frontend-src-components--slots-table",
	"event-detail-creating": "_-_-_-profile-frontend-src-components--event-detail-creating",
	"eventDetailCreating": "_-_-_-profile-frontend-src-components--event-detail-creating",
	"event-detail-creating__section-scrollbars__view": "_-_-_-profile-frontend-src-components--event-detail-creating__section-scrollbars__view",
	"eventDetailCreating__sectionScrollbars__view": "_-_-_-profile-frontend-src-components--event-detail-creating__section-scrollbars__view"
};
module.exports = exports;
