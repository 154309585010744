// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._-_-_-profile-frontend-src-components-profile-sidebar-info-items--info-items__content{display:flex;flex-direction:column;align-items:flex-start}._-_-_-profile-frontend-src-components-profile-sidebar-info-items--info-items__separator{margin:20px 0 20px 0;border-bottom:1px solid #3C3D47}\n", ""]);
// Exports
exports.locals = {
	"info-items__content": "_-_-_-profile-frontend-src-components-profile-sidebar-info-items--info-items__content",
	"infoItems__content": "_-_-_-profile-frontend-src-components-profile-sidebar-info-items--info-items__content",
	"info-items__separator": "_-_-_-profile-frontend-src-components-profile-sidebar-info-items--info-items__separator",
	"infoItems__separator": "_-_-_-profile-frontend-src-components-profile-sidebar-info-items--info-items__separator"
};
module.exports = exports;
