// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._-_-_-profile-frontend-src-components-gamification-empty--gamification-empty{font-weight:400;font-size:15px;line-height:20px;flex:1;padding:12px 16px;margin:16px 16px 0 16px;background:#3C3D48;border-radius:8px}\n", ""]);
// Exports
exports.locals = {
	"gamification-empty": "_-_-_-profile-frontend-src-components-gamification-empty--gamification-empty",
	"gamificationEmpty": "_-_-_-profile-frontend-src-components-gamification-empty--gamification-empty"
};
module.exports = exports;
