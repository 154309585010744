// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._-_-_-profile-frontend-src-components-profile--profile-user{display:flex;background:#2B2C34;padding:24px;border-radius:0px;margin-bottom:16px}._-_-_-profile-frontend-src-components-profile--profile-user__actions{display:flex;flex-direction:column;margin-top:4px}._-_-_-profile-frontend-src-components-profile--profile-user__actions-btn{margin-top:12px}._-_-_-profile-frontend-src-components-profile--profile-user__actions-btn_coins .anticon{color:#FF5C5C}._-_-_-profile-frontend-src-components-profile--profile-user__avatar-container{display:flex;flex:0 0 200px;flex-direction:column;width:200px}._-_-_-profile-frontend-src-components-profile--profile-user__info-container{margin-left:24px}._-_-_-profile-frontend-src-components-profile--profile-user__skeleton-wrapper{display:flex;flex-direction:column;margin-bottom:20px}\n", ""]);
// Exports
exports.locals = {
	"profile-user": "_-_-_-profile-frontend-src-components-profile--profile-user",
	"profileUser": "_-_-_-profile-frontend-src-components-profile--profile-user",
	"profile-user__actions": "_-_-_-profile-frontend-src-components-profile--profile-user__actions",
	"profileUser__actions": "_-_-_-profile-frontend-src-components-profile--profile-user__actions",
	"profile-user__actions-btn": "_-_-_-profile-frontend-src-components-profile--profile-user__actions-btn",
	"profileUser__actionsBtn": "_-_-_-profile-frontend-src-components-profile--profile-user__actions-btn",
	"profile-user__actions-btn_coins": "_-_-_-profile-frontend-src-components-profile--profile-user__actions-btn_coins",
	"profileUser__actionsBtn_coins": "_-_-_-profile-frontend-src-components-profile--profile-user__actions-btn_coins",
	"profile-user__avatar-container": "_-_-_-profile-frontend-src-components-profile--profile-user__avatar-container",
	"profileUser__avatarContainer": "_-_-_-profile-frontend-src-components-profile--profile-user__avatar-container",
	"profile-user__info-container": "_-_-_-profile-frontend-src-components-profile--profile-user__info-container",
	"profileUser__infoContainer": "_-_-_-profile-frontend-src-components-profile--profile-user__info-container",
	"profile-user__skeleton-wrapper": "_-_-_-profile-frontend-src-components-profile--profile-user__skeleton-wrapper",
	"profileUser__skeletonWrapper": "_-_-_-profile-frontend-src-components-profile--profile-user__skeleton-wrapper"
};
module.exports = exports;
