// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._-_-_-profile-frontend-src-components-skills--profile-skills{display:flex;flex-direction:column;background:#2B2C34;border-radius:0px;height:auto;margin:10px;min-width:373px;max-width:415px}@media screen and (min-device-width: 1800px){._-_-_-profile-frontend-src-components-skills--profile-skills{min-width:386px}}._-_-_-profile-frontend-src-components-skills--profile-skills__skill-btn{margin-left:8px;margin-top:8px}._-_-_-profile-frontend-src-components-skills--profile-skills__wrapper{padding:24px 24px 0}._-_-_-profile-frontend-src-components-skills--profile-skills__container{display:flex;flex-wrap:wrap;padding:16px 24px 24px 16px}._-_-_-profile-frontend-src-components-skills--profile-skills__delete-notice{font-weight:400;font-size:15px;line-height:20px}._-_-_-profile-frontend-src-components-skills--profile-skills__delete-btn{background:#FF5C5C}\n", ""]);
// Exports
exports.locals = {
	"profile-skills": "_-_-_-profile-frontend-src-components-skills--profile-skills",
	"profileSkills": "_-_-_-profile-frontend-src-components-skills--profile-skills",
	"profile-skills__skill-btn": "_-_-_-profile-frontend-src-components-skills--profile-skills__skill-btn",
	"profileSkills__skillBtn": "_-_-_-profile-frontend-src-components-skills--profile-skills__skill-btn",
	"profile-skills__wrapper": "_-_-_-profile-frontend-src-components-skills--profile-skills__wrapper",
	"profileSkills__wrapper": "_-_-_-profile-frontend-src-components-skills--profile-skills__wrapper",
	"profile-skills__container": "_-_-_-profile-frontend-src-components-skills--profile-skills__container",
	"profileSkills__container": "_-_-_-profile-frontend-src-components-skills--profile-skills__container",
	"profile-skills__delete-notice": "_-_-_-profile-frontend-src-components-skills--profile-skills__delete-notice",
	"profileSkills__deleteNotice": "_-_-_-profile-frontend-src-components-skills--profile-skills__delete-notice",
	"profile-skills__delete-btn": "_-_-_-profile-frontend-src-components-skills--profile-skills__delete-btn",
	"profileSkills__deleteBtn": "_-_-_-profile-frontend-src-components-skills--profile-skills__delete-btn"
};
module.exports = exports;
