// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._-_-_-profile-frontend-src-components-gamification-list-item-loading--item-loading{flex:1;display:flex;flex-direction:column}._-_-_-profile-frontend-src-components-gamification-list-item-loading--item-loading__header{display:flex;align-items:center;padding:24px 24px 20px}._-_-_-profile-frontend-src-components-gamification-list-item-loading--item-loading__header-info{display:flex;flex-direction:column;margin-left:12px}._-_-_-profile-frontend-src-components-gamification-list-item-loading--item-loading__body{padding:0 24px 24px;border-bottom:1px solid #3C3D48}\n", ""]);
// Exports
exports.locals = {
	"item-loading": "_-_-_-profile-frontend-src-components-gamification-list-item-loading--item-loading",
	"itemLoading": "_-_-_-profile-frontend-src-components-gamification-list-item-loading--item-loading",
	"item-loading__header": "_-_-_-profile-frontend-src-components-gamification-list-item-loading--item-loading__header",
	"itemLoading__header": "_-_-_-profile-frontend-src-components-gamification-list-item-loading--item-loading__header",
	"item-loading__header-info": "_-_-_-profile-frontend-src-components-gamification-list-item-loading--item-loading__header-info",
	"itemLoading__headerInfo": "_-_-_-profile-frontend-src-components-gamification-list-item-loading--item-loading__header-info",
	"item-loading__body": "_-_-_-profile-frontend-src-components-gamification-list-item-loading--item-loading__body",
	"itemLoading__body": "_-_-_-profile-frontend-src-components-gamification-list-item-loading--item-loading__body"
};
module.exports = exports;
