// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._-_-_-components-frontend-src-components-slots-table--slots-table .ant-table-thead>tr>th{padding:0;border-bottom:none;background:#2B2C34}._-_-_-components-frontend-src-components-slots-table--slots-table .ant-table-cell-fix-left{z-index:3}._-_-_-components-frontend-src-components-slots-table--slots-table .ant-table-tbody>tr>td{padding:0;border-bottom:none}._-_-_-components-frontend-src-components-slots-table--slots-table .ant-table-tbody>tr.ant-table-row:hover>td{background:#2A3F57}._-_-_-components-frontend-src-components-slots-table--slots-table .ant-table-body{-ms-overflow-style:none;scrollbar-width:none}._-_-_-components-frontend-src-components-slots-table--slots-table .ant-table-body::-webkit-scrollbar{display:none}._-_-_-components-frontend-src-components-slots-table--slots-table .ant-table-sticky-scroll-bar{height:0px}\n", ""]);
// Exports
exports.locals = {
	"slots-table": "_-_-_-components-frontend-src-components-slots-table--slots-table",
	"slotsTable": "_-_-_-components-frontend-src-components-slots-table--slots-table"
};
module.exports = exports;
