// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._-_-_-profile-frontend-src-components-profile-info-biography--info-biography{display:flex;flex-direction:column}._-_-_-profile-frontend-src-components-profile-info-biography--info-biography__edit{display:inline-block;padding:12px 16px;background-color:#3C3D48;width:100%}._-_-_-profile-frontend-src-components-profile-info-biography--info-biography__edit-link{color:var(--myteam-themeVariables-colorLinkBrand)}._-_-_-profile-frontend-src-components-profile-info-biography--info-biography__edit-link-text{color:inherit}._-_-_-profile-frontend-src-components-profile-info-biography--info-biography__content{display:flex;flex-direction:column;align-items:flex-start}._-_-_-profile-frontend-src-components-profile-info-biography--info-biography__wrapper{display:flex;flex-direction:column;align-items:flex-start}._-_-_-profile-frontend-src-components-profile-info-biography--info-biography__wrapper:not(:first-child){margin-top:12px}._-_-_-profile-frontend-src-components-profile-info-biography--info-biography__content-wrapper{display:flex;flex-wrap:wrap}._-_-_-profile-frontend-src-components-profile-info-biography--info-biography__content-value{font-weight:600;font-size:15px;line-height:20px;letter-spacing:0.1px;white-space:pre-wrap}\n", ""]);
// Exports
exports.locals = {
	"info-biography": "_-_-_-profile-frontend-src-components-profile-info-biography--info-biography",
	"infoBiography": "_-_-_-profile-frontend-src-components-profile-info-biography--info-biography",
	"info-biography__edit": "_-_-_-profile-frontend-src-components-profile-info-biography--info-biography__edit",
	"infoBiography__edit": "_-_-_-profile-frontend-src-components-profile-info-biography--info-biography__edit",
	"info-biography__edit-link": "_-_-_-profile-frontend-src-components-profile-info-biography--info-biography__edit-link",
	"infoBiography__editLink": "_-_-_-profile-frontend-src-components-profile-info-biography--info-biography__edit-link",
	"info-biography__edit-link-text": "_-_-_-profile-frontend-src-components-profile-info-biography--info-biography__edit-link-text",
	"infoBiography__editLinkText": "_-_-_-profile-frontend-src-components-profile-info-biography--info-biography__edit-link-text",
	"info-biography__content": "_-_-_-profile-frontend-src-components-profile-info-biography--info-biography__content",
	"infoBiography__content": "_-_-_-profile-frontend-src-components-profile-info-biography--info-biography__content",
	"info-biography__wrapper": "_-_-_-profile-frontend-src-components-profile-info-biography--info-biography__wrapper",
	"infoBiography__wrapper": "_-_-_-profile-frontend-src-components-profile-info-biography--info-biography__wrapper",
	"info-biography__content-wrapper": "_-_-_-profile-frontend-src-components-profile-info-biography--info-biography__content-wrapper",
	"infoBiography__contentWrapper": "_-_-_-profile-frontend-src-components-profile-info-biography--info-biography__content-wrapper",
	"info-biography__content-value": "_-_-_-profile-frontend-src-components-profile-info-biography--info-biography__content-value",
	"infoBiography__contentValue": "_-_-_-profile-frontend-src-components-profile-info-biography--info-biography__content-value"
};
module.exports = exports;
