// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._-_-_-profile-frontend-src-components-qr-card--qr-card{display:flex;justify-content:space-between;padding:24px;color:#909499}._-_-_-profile-frontend-src-components-qr-card--qr-card__wrapper{flex:0 0 66%;display:flex;flex-direction:column}._-_-_-profile-frontend-src-components-qr-card--qr-card__title{display:flex}._-_-_-profile-frontend-src-components-qr-card--qr-card__title-icon{color:#76787A;margin-right:8px}\n", ""]);
// Exports
exports.locals = {
	"qr-card": "_-_-_-profile-frontend-src-components-qr-card--qr-card",
	"qrCard": "_-_-_-profile-frontend-src-components-qr-card--qr-card",
	"qr-card__wrapper": "_-_-_-profile-frontend-src-components-qr-card--qr-card__wrapper",
	"qrCard__wrapper": "_-_-_-profile-frontend-src-components-qr-card--qr-card__wrapper",
	"qr-card__title": "_-_-_-profile-frontend-src-components-qr-card--qr-card__title",
	"qrCard__title": "_-_-_-profile-frontend-src-components-qr-card--qr-card__title",
	"qr-card__title-icon": "_-_-_-profile-frontend-src-components-qr-card--qr-card__title-icon",
	"qrCard__titleIcon": "_-_-_-profile-frontend-src-components-qr-card--qr-card__title-icon"
};
module.exports = exports;
