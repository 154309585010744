// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._-_-_-ant-src-components-ui-auto-complete--ui-auto-complete .ant-input-suffix{color:#76787A;-moz-transition:0.5s all;-webkit-transition:0.5s all}._-_-_-ant-src-components-ui-auto-complete--ui-auto-complete .ant-input-affix-wrapper-focused .ant-input-suffix{-moz-transform:rotate(180deg);-webkit-transform:rotate(180deg)}\n", ""]);
// Exports
exports.locals = {
	"ui-auto-complete": "_-_-_-ant-src-components-ui-auto-complete--ui-auto-complete",
	"uiAutoComplete": "_-_-_-ant-src-components-ui-auto-complete--ui-auto-complete"
};
module.exports = exports;
