// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._-_-_-profile-frontend-src-components-contacts-contact--contact{display:flex;align-items:center;padding:10px 24px;color:#76787A}._-_-_-profile-frontend-src-components-contacts-contact--contact__wrapper{overflow:hidden;white-space:nowrap;text-overflow:ellipsis}._-_-_-profile-frontend-src-components-contacts-contact--contact__title{font-weight:600;font-size:11px;line-height:12px;color:#909499;display:block;margin-left:8px}._-_-_-profile-frontend-src-components-contacts-contact--contact__label{font-weight:600;font-size:15px;line-height:20px;letter-spacing:0.1px;margin-left:8px}._-_-_-profile-frontend-src-components-contacts-contact--contact__copy-btn{margin-left:auto}\n", ""]);
// Exports
exports.locals = {
	"contact": "_-_-_-profile-frontend-src-components-contacts-contact--contact",
	"contact__wrapper": "_-_-_-profile-frontend-src-components-contacts-contact--contact__wrapper",
	"contact__title": "_-_-_-profile-frontend-src-components-contacts-contact--contact__title",
	"contact__label": "_-_-_-profile-frontend-src-components-contacts-contact--contact__label",
	"contact__copy-btn": "_-_-_-profile-frontend-src-components-contacts-contact--contact__copy-btn",
	"contact__copyBtn": "_-_-_-profile-frontend-src-components-contacts-contact--contact__copy-btn"
};
module.exports = exports;
