// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._-_-_-profile-frontend-src-components-profile-info-references--info-references{display:flex;flex-direction:column}._-_-_-profile-frontend-src-components-profile-info-references--info-references__header{display:flex;flex-direction:column;margin-bottom:20px}._-_-_-profile-frontend-src-components-profile-info-references--info-references__links{display:flex;flex-wrap:wrap}._-_-_-profile-frontend-src-components-profile-info-references--info-references__links-item{height:24px;margin-top:4px;padding-right:8px;border-radius:56px;color:#E1E3E6}._-_-_-profile-frontend-src-components-profile-info-references--info-references__links-item:not(._-_-_-profile-frontend-src-components-profile-info-references--info-references__links-item:last-child){margin-right:8px}._-_-_-profile-frontend-src-components-profile-info-references--info-references__links-item-icon{margin-right:8px}._-_-_-profile-frontend-src-components-profile-info-references--info-references__links-item-title{color:inherit}._-_-_-profile-frontend-src-components-profile-info-references--info-references__content{display:flex;flex-direction:column;align-items:flex-start}._-_-_-profile-frontend-src-components-profile-info-references--info-references__projects{font-weight:600;font-size:15px;line-height:20px;letter-spacing:0.1px;white-space:pre-wrap}._-_-_-profile-frontend-src-components-profile-info-references--info-references__wrapper{display:flex;flex-direction:column;align-items:flex-start}._-_-_-profile-frontend-src-components-profile-info-references--info-references__wrapper:not(:first-child){margin-top:12px}\n", ""]);
// Exports
exports.locals = {
	"info-references": "_-_-_-profile-frontend-src-components-profile-info-references--info-references",
	"infoReferences": "_-_-_-profile-frontend-src-components-profile-info-references--info-references",
	"info-references__header": "_-_-_-profile-frontend-src-components-profile-info-references--info-references__header",
	"infoReferences__header": "_-_-_-profile-frontend-src-components-profile-info-references--info-references__header",
	"info-references__links": "_-_-_-profile-frontend-src-components-profile-info-references--info-references__links",
	"infoReferences__links": "_-_-_-profile-frontend-src-components-profile-info-references--info-references__links",
	"info-references__links-item": "_-_-_-profile-frontend-src-components-profile-info-references--info-references__links-item",
	"infoReferences__linksItem": "_-_-_-profile-frontend-src-components-profile-info-references--info-references__links-item",
	"info-references__links-item-icon": "_-_-_-profile-frontend-src-components-profile-info-references--info-references__links-item-icon",
	"infoReferences__linksItemIcon": "_-_-_-profile-frontend-src-components-profile-info-references--info-references__links-item-icon",
	"info-references__links-item-title": "_-_-_-profile-frontend-src-components-profile-info-references--info-references__links-item-title",
	"infoReferences__linksItemTitle": "_-_-_-profile-frontend-src-components-profile-info-references--info-references__links-item-title",
	"info-references__content": "_-_-_-profile-frontend-src-components-profile-info-references--info-references__content",
	"infoReferences__content": "_-_-_-profile-frontend-src-components-profile-info-references--info-references__content",
	"info-references__projects": "_-_-_-profile-frontend-src-components-profile-info-references--info-references__projects",
	"infoReferences__projects": "_-_-_-profile-frontend-src-components-profile-info-references--info-references__projects",
	"info-references__wrapper": "_-_-_-profile-frontend-src-components-profile-info-references--info-references__wrapper",
	"infoReferences__wrapper": "_-_-_-profile-frontend-src-components-profile-info-references--info-references__wrapper"
};
module.exports = exports;
