// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._-_-_-profile-frontend-src-components-skills-empty--skills-empty{width:100%;padding:12px 16px;margin:8px 0 0 8px;background:#3C3D48;border-radius:8px}\n", ""]);
// Exports
exports.locals = {
	"skills-empty": "_-_-_-profile-frontend-src-components-skills-empty--skills-empty",
	"skillsEmpty": "_-_-_-profile-frontend-src-components-skills-empty--skills-empty"
};
module.exports = exports;
