// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._-_-_-ant-src-components-ui-modals-modal-footer--ui-modal-footer{padding:24px}._-_-_-ant-src-components-ui-modals-modal-footer--ui-modal-footer_with-border{border-top:1px solid #3C3D47}\n", ""]);
// Exports
exports.locals = {
	"ui-modal-footer": "_-_-_-ant-src-components-ui-modals-modal-footer--ui-modal-footer",
	"uiModalFooter": "_-_-_-ant-src-components-ui-modals-modal-footer--ui-modal-footer",
	"ui-modal-footer_with-border": "_-_-_-ant-src-components-ui-modals-modal-footer--ui-modal-footer_with-border",
	"uiModalFooter_withBorder": "_-_-_-ant-src-components-ui-modals-modal-footer--ui-modal-footer_with-border"
};
module.exports = exports;
