// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._-_-_-profile-frontend-src-components-profile-info--profile-info{width:100%}._-_-_-profile-frontend-src-components-profile-info--profile-info__separator{margin:20px 0 20px 0;border-bottom:1px solid #3C3D47}\n", ""]);
// Exports
exports.locals = {
	"profile-info": "_-_-_-profile-frontend-src-components-profile-info--profile-info",
	"profileInfo": "_-_-_-profile-frontend-src-components-profile-info--profile-info",
	"profile-info__separator": "_-_-_-profile-frontend-src-components-profile-info--profile-info__separator",
	"profileInfo__separator": "_-_-_-profile-frontend-src-components-profile-info--profile-info__separator"
};
module.exports = exports;
